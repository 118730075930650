import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/alirun/Work/Blockeys/ofi-landing/src/layouts/blogPost.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "shooting-for-the-moon",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#shooting-for-the-moon",
        "aria-label": "shooting for the moon permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Shooting for the moon`}</h1>
    <p>{`We plugged in UMA’s optimistic oracle to get access to cheap and reliable real-world data, which means that we can provide insurance against theoretically endless numbers of events. As a token of appreciation, we started with a project by one of the most prominent entrepreneurs and visioners of our time, Elon Musk. Not only SpaceX has revolutionized the space transportation industry, being the very first private company to successfully launch rockets into space, but it also offered the rocket ride-sharing business SpaceX Smallsat Rideshare–a program that allows multiple payload bookers to share space rides.
With Opium insurance, anyone with a financial stake in SpaceX launch can hedge the risks, while others can just make a wager and speculation on the success or failure of the launch.`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.coindesk.com/opium-uma-spacex-decentralized-insurance-contract"
      }}><strong parentName="a">{`Opium, UMA to Launch Decentralized Insurance for SpaceX Flights`}</strong></a></p>
    <h1 {...{
      "id": "product-updates",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#product-updates",
        "aria-label": "product updates permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Product updates`}</h1>
    <h2 {...{
      "id": "first-turbo-on-bsc-and-bridge-protection",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#first-turbo-on-bsc-and-bridge-protection",
        "aria-label": "first turbo on bsc and bridge protection permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`First Turbo on BSC and Bridge protection`}</h2>
    <p>{`Following the Opium Protocol launch on Polygon (ex-Matic) and the OPIUM token launch on BSC, the Opium Protocol was integrated and went live on the Binance Smart Chain. The first live product is Opium Turbo ETH that is actively traded on Matic–so now you can choose where to buy your Turbos or try both Matic and BSC. Stay tuned for more gas-efficient and user-friendly derivatives to come in June.`}</p>
    <p><a parentName="p" {...{
        "href": "https://opium.finance/blog/opium-bsc-blog/OpiumonBSC/"
      }}><strong parentName="a">{`Opium Protocol is live on Binance Smart Chain`}</strong></a>{` `}</p>
    <p>{`As the importance of side-chains increases in the DeFi, more ways to secure side-chains bridges emerge.`}</p>
    <p>{`Side-chains are usually forks of Ethereum or other blockchains, which can provide users with lower gas fees due to the specific advantages and transaction amount. There is a bridge that connects such a side-chain with the main net. It is safe to say that the side-chain is as good as its bridge. That’s why we introduced Opium Bridge Protection–tokenized and tradable decentralized insurance that currently protects two bridges, Polygon bridge and oxDAI against hacking and money loss. `}</p>
    <p>{`As with other Opium products, Bridge protection offers two sides: one of the buyer, another of the staker. Anyone bullish on the Bridge can stake money and earn fees from selling insurance, while users who seek protection can buy monthly coverage and hedge risks.`}</p>
    <p><a parentName="p" {...{
        "href": "https://opium.finance/blog/bridge-protection/blog-bridge/"
      }}><strong parentName="a">{`Introducing Opium Bridge Protection`}</strong></a></p>
    <h2 {...{
      "id": "listing-on-bancor",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#listing-on-bancor",
        "aria-label": "listing on bancor permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Listing on Bancor`}</h2>
    <p>{`We submitted a proposal to the Bancor community to get $OPIUM whitelisted on Bancor with a co-investment of 150,000 BTN. All votes received were in favour, but we missed less than 2% to get the necessary quorum of 40%. We are attempting again, second voting has started Monday, 31 May. Fingers crossed!`}</p>
    <h1 {...{
      "id": "media--events",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#media--events",
        "aria-label": "media  events permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Media & Events`}</h1>
    <p><a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=XaK9uZ5mCVk"
      }}><strong parentName="a">{`Token Design Lead Matus Steis from the Outlier Ventures accelerator spoke with Andrey Belyakov about decentralized derivatives in his YouTube series.`}</strong></a></p>
    <p><iframe parentName="p" {...{
        "width": "100%",
        "height": 315,
        "src": "https://www.youtube-nocookie.com/embed/XaK9uZ5mCVk?rel=0&feature=emb_title",
        "frameBorder": "0",
        "allow": "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture",
        "allowFullScreen": true
      }}></iframe></p>
    <h1 {...{
      "id": "upcoming-in-june",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#upcoming-in-june",
        "aria-label": "upcoming in june permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Upcoming in June`}</h1>
    <p>{`We are about to launch two new Turbos: Turbo short and Turbo volatility (working names) so that you have more choices and better align with your investment strategy. For the Opium tokens holders who are not staking their assets for one reason or another, we will introduce a simpler way to get a return from $OPIUM than liquidity mining. Stay tuned and stay in touch–let us know if you have any feedback, suggestions, critics or appreciation. Below are the channels to get in touch. `}</p>
    <h1 {...{
      "id": "stay-tuned",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#stay-tuned",
        "aria-label": "stay tuned permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Stay tuned`}</h1>
    <p>{`Opium Protocol is a universal and robust DeFi protocol that allows for creating, settling, and trading decentralized derivatives.`}</p>
    <p><a parentName="p" {...{
        "href": "https://app.opium.finance/"
      }}><strong parentName="a">{`Try out Opium Finance`}</strong></a>{` or `}<a parentName="p" {...{
        "href": "https://docs.opium.network/"
      }}><strong parentName="a">{`Explore Opium Protocol.`}</strong></a></p>
    <p>{`Stay informed and follow `}<a parentName="p" {...{
        "href": "https://twitter.com/Opium_Network"
      }}><strong parentName="a">{`Opium.Team on Twitter`}</strong></a>{`.`}</p>
    <p>{`Did you know that you can subscribe to our `}<a parentName="p" {...{
        "href": "https://t.me/OpiumFinance"
      }}><strong parentName="a">{`News Channel`}</strong></a>{` to get more exciting news delivered to your morning coffee?`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      